export const HANDLE_GET_ZIPCODES = 'HANDLE_GET_ZIPCODES'
export const GET_ZIPCODES = 'GET_ZIPCODES'

export const HANDLE_GET_ZIPCODE = 'HANDLE_GET_ZIPCODE'
export const GET_ZIPCODE = 'GET_ZIPCODE'

export const HANDLE_GET_ZIPCODE_HOUSING = 'HANDLE_GET_ZIPCODE_HOUSING'
export const GET_ZIPCODE_HOUSING = 'GET_ZIPCODE_HOUSING'

export const HANDLE_GET_ZIPCODE_PROPERTY_SUMMARY = 'HANDLE_GET_ZIPCODE_PROPERTY_SUMMARY'
export const GET_ZIPCODE_PROPERTY_SUMMARY = 'GET_ZIPCODE_PROPERTY_SUMMARY'

export const GET_ZIPCODE_PROPERTIES = 'GET_ZIPCODE_PROPERTIES'
