export const BOOKMARK_PROPERTY = "BOOKMARK_PROPERTY";
export const UNBOOKMARK_PROPERTY = "UNBOOKMARK_PROPERTY";
export const GET_USER_BOOKMARKED_PROPERTIES = "GET_USER_BOOKMARKED_PROPERTIES";

export const GET_USER_SAVED_CUSTOM_SEARCHES = "GET_USER_SAVED_CUSTOM_SEARCHES";
export const SAVE_CUSTOM_SEARCH = "SAVE_CUSTOM_SEARCH";
export const DELETE_CUSTOM_SEARCH = "DELETE_CUSTOM_SEARCH";
export const UPDATE_CUSTOM_SEARCH = "UPDATE_CUSTOM_SEARCH";

export const CLEAR_MY_DASHBOARD = "CLEAR_MY_DASHBOARD";
