export const ADD_NEW_CONDITION = 'ADD_NEW_CONDITION'
export const ADD_NEW_CONDITION_GROUP = 'ADD_NEW_CONDITION_GROUP'
export const CHANGE_CONDITION_TYPE = 'CHANGE_CONDITION_TYPE'
export const UPDATE_CONDITION = 'UPDATE_CONDITION'
export const REMOVE_CONDITION = 'REMOVE_CONDITION'
export const REMOVE_CONDITION_GROUP = 'REMOVE_CONDITION_GROUP'
export const ADD_FILTER = 'ADD_FILTER'
export const UPDATE_FILTER = 'UPDATE_FILTER'
export const REMOVE_FILTER = 'REMOVE_FILTER'
export const ADVANCED_SEARCH = 'ADVANCED_SEARCH'
export const GET_ADVANCED_SEARCH = 'GET_ADVANCED_SEARCH'
export const HANDLE_GET_ADVANCED_SEARCH = 'HANDLE_GET_ADVANCED_SEARCH'
export const ADD_GEOGRAPHY = 'ADD_GEOGRAPHY'
export const UPDATE_GEOGRAPHY = 'UPDATE_GEOGRAPHY'
export const REMOVE_GEOGRAPHY = 'REMOVE_GEOGRAPHY'
export const RESET_ADVANCED_SEARCH_REDUCER = 'RESET_ADVANCED_SEARCH_REDUCER'
export const REPLACE_PROPERTY_FILTER = 'REPLACE_PROPERTY_FILTER'
export const SET_CUSTOM_SEARCH_RESULTS = 'SET_CUSTOM_SEARCH_RESULTS'
export const FORCE_UPDATE_SEARCH = 'FORCE_UPDATE_SEARCH'
export const SET_SEARCH_TABLE_STATE = 'SET_SEARCH_TABLE_STATE'
