export const SET_GEOGRAPHY_TYPE = 'SET_GEOGRAPHY_TYPE'
export const SET_GEOGRAPHY_ID = 'SET_GEOGRAPHY_ID'
export const SET_GEOGRAPHY_TYPE_AND_ID = 'SET_GEOGRAPHY_TYPE_AND_ID'
export const SET_GEOGRAPHY_REQUESTS = 'SET_GEOGRAPHY_REQUESTS'
export const SET_ADVANCED_SEARCH_REQUEST = 'SET_ADVANCED_SEARCH_REQUEST'
export const CLEAR_ADVANCED_SEARCH_REQUEST = 'CLEAR_ADVANCED_SEARCH_REQUEST'
export const SET_PROPERTY_BUILDING_LOOKUP_REQUESTS = 'SET_PROPERTY_BUILDING_LOOKUP_REQUESTS'
export const SET_PROPERTY = 'SET_PROPERTY'
export const SET_BUILDING = 'SET_BUILDING'
export const SET_PROPERTY_AND_BUILDING = 'SET_PROPERTY_AND_BUILDING'
export const HANDLE_DATA_REQUEST = 'HANDLE_DATA_REQUEST'
export const REMOVE_REQUEST_TYPE = 'REMOVE_REQUEST_TYPE'
export const SET_APP_STATE = 'SET_APP_STATE'
export const TOGGLE_SELECTED_REQUEST = 'TOGGLE_SELECTED_REQUEST'
export const GET_WOW_PROPERTY_DATA = 'GET_WOW_PROPERTY_DATA'
export const SET_WOW_PROPERTY_DATA = 'SET_WOW_PROPERTY_DATA'
