export const HANDLE_GET_STATE_SENATES = 'HANDLE_GET_STATE_SENATES'
export const GET_STATE_SENATES = 'GET_STATE_SENATES'

export const HANDLE_GET_STATE_SENATE = 'HANDLE_GET_STATE_SENATE'
export const GET_STATE_SENATE = 'GET_STATE_SENATE'

export const HANDLE_GET_STATE_SENATE_HOUSING = 'HANDLE_GET_STATE_SENATE_HOUSING'
export const GET_STATE_SENATE_HOUSING = 'GET_STATE_SENATE_HOUSING'

export const HANDLE_GET_STATE_SENATE_PROPERTY_SUMMARY = 'HANDLE_GET_STATE_SENATE_PROPERTY_SUMMARY'
export const GET_STATE_SENATE_PROPERTY_SUMMARY = 'GET_STATE_SENATE_PROPERTY_SUMMARY'

export const GET_STATE_SENATE_PROPERTIES = 'GET_STATE_SENATE_PROPERTIES'
