export const SET_DASHBOARD_MAP_ZOOM = 'SET_DASHBOARD_MAP_ZOOM'
export const LOAD_RESULT_FILTERS = 'LOAD_RESULT_FILTERS'
export const SET_HOUSING_TYPE_RESULT_FILTER = 'SET_HOUSING_TYPE_RESULT_FILTER'
export const SET_HOUSING_TYPE_RESULTS_INDEX = 'SET_HOUSING_TYPE_RESULTS_INDEX'
export const SET_MAP_FILTER_DATE = 'SET_MAP_FILTER_DATE'
export const SET_DASHBOARD_CUSTOM_VIEW = 'SET_DASHBOARD_CUSTOM_VIEW'
export const TOGGLE_SELECTED_AMOUNT_FILTER = 'TOGGLE_SELECTED_AMOUNT_FILTER'
export const UPDATE_AMOUNT_FILTER = 'UPDATE_AMOUNT_FILTER'
export const SET_TOTAL_PROPERTY_RESULTS = 'SET_TOTAL_PROPERTY_RESULTS'
export const SET_DASHBOARD_TABLE_VIEW = 'SET_DASHBOARD_TABLE_VIEW'
export const SET_DASHBOARD_TABLE_STATE = 'SET_DASHBOARD_TABLE_STATE'
export const SET_DASHBOARD_FILTER_CONDITION = 'SET_DASHBOARD_FILTER_CONDITION'
export const RESET_DASHBOARD_STATE = 'RESET_DASHBOARD_STATE'
