export const HANDLE_GET_STATE_ASSEMBLIES = 'HANDLE_GET_STATE_ASSEMBLIES'
export const GET_STATE_ASSEMBLIES = 'GET_STATE_ASSEMBLIES'

export const HANDLE_GET_STATE_ASSEMBLY = 'HANDLE_GET_STATE_ASSEMBLY'
export const GET_STATE_ASSEMBLY = 'GET_STATE_ASSEMBLY'

export const HANDLE_GET_STATE_ASSEMBLY_HOUSING = 'HANDLE_GET_STATE_ASSEMBLY_HOUSING'
export const GET_STATE_ASSEMBLY_HOUSING = 'GET_STATE_ASSEMBLY_HOUSING'

export const HANDLE_GET_STATE_ASSEMBLY_PROPERTY_SUMMARY = 'HANDLE_GET_STATE_ASSEMBLY_PROPERTY_SUMMARY'
export const GET_STATE_ASSEMBLY_PROPERTY_SUMMARY = 'GET_STATE_ASSEMBLY_PROPERTY_SUMMARY'

export const GET_STATE_ASSEMBLY_PROPERTIES = 'GET_STATE_ASSEMBLY_PROPERTIES'
